var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "recommend-list" },
      [
        _vm.recommendList && _vm.recommendList.length > 0
          ? _c(
              "ul",
              _vm._l(_vm.recommendList, function (item) {
                return _c(
                  "li",
                  {
                    key: item.id,
                    on: {
                      click: function ($event) {
                        return _vm.$showNoAuthMsg()
                      },
                    },
                  },
                  [
                    _c("h1", { staticClass: "title" }, [
                      _c("a", {
                        attrs: { href: item.link },
                        domProps: { textContent: _vm._s(item.title) },
                      }),
                    ]),
                    _c("div", [
                      _c(
                        "a",
                        [
                          _c("el-image", {
                            staticClass: "image",
                            attrs: { src: item.image, alt: item.title },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("el-divider"),
                  ],
                  1
                )
              }),
              0
            )
          : _c("el-empty", { attrs: { "image-size": 165 } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }