var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "main-wrap" }, [
        _c("div", { staticClass: "right-aside-container" }, [
          _c(
            "div",
            { staticClass: "right-aside-wrap" },
            [
              _c("RecommendListComponent", {
                attrs: { "recommend-list": _vm.recommendList },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "left-aside-container" }, [
          _c(
            "div",
            { staticClass: "left-aside-wrap" },
            [
              _vm.categoryList && _vm.categoryList.length > 0
                ? _c(
                    "div",
                    { staticClass: "article-list" },
                    _vm._l(_vm.categoryList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "key-list" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "key-item-list" },
                            _vm._l(item.child, function (k) {
                              return _c(
                                "div",
                                {
                                  key: k.id,
                                  staticClass: "key-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkList(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(k.name))]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _c("el-empty", { attrs: { "image-size": 200 } }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }